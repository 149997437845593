.size-0 {
  font-size: 100%;
}

.size-1 {
  font-size: 115%;
}

.size-2 {
  font-size: 130%;
}

.paragraphs-spacing p {
  margin-bottom: 2em;
}

.lines-spacing {
  line-height: 2em;
}

.words-spacing {
  word-spacing: 0.5em;
}

.letters-spacing {
  letter-spacing: 0.25em;
}
